.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    border-top: 2px solid #F1F2F3;
    border-bottom: 2px solid #F1F2F3;
    margin-top: -2px;
    padding: 0px 5px;

    p {
        margin-bottom: 0px; }

    > :first-child {
        flex-grow: 1;
        font-weight: 500;
        font-size: 10.4923px;
        line-height: 14px; }

    > :last-child {
        max-width: 150px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 30px;
            height: 23px;
            cursor: pointer;
            transition: transform ease .2s;

            &:last-of-type {
                height: 30px; }

            &:hover {
                transform: scale(1.3); } } }

    .input {
        padding: 0px;
        border-bottom: 1px solid black;
        margin-right: 5px;
        height: 36px; } }
