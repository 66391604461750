.group {
    max-width: 510px; }

.groupTop {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 5px;

    h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0px;
        margin-right: 5px; }

    img {
        height: 30px;
        width: 30px;
        cursor: pointer;
        transition: transform ease .2s;
        &:hover {
            transform: scale(1.3); } } }
