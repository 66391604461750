.customRadioButton {
    width: 40px;
    height: 14px;
    background: #EAE9E4;
    border-radius: 15px;
    display: flex;
    align-items: center;

    &.active {
        background: #fad1cb7e;
        mix-blend-mode: normal;
        span {
            background: #EF6752;
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602); } }


    span {
        cursor: pointer;
        background: #F1F1F1;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        opacity: 0;
        &.active {
            opacity: 1; } } }

