.operator-orders {
    &__header {
        display: flex;
        height: 36px;
        justify-content: space-between;
        margin-bottom: 25px;
        flex-wrap: wrap;

        &-left, &-right {
            display: flex;
            justify-content: space-between;
            width: 100%; }

        &-left {
            max-width: 336px;
            .button {
                line-height: 16px;
                padding: 10px 30px; }
            .btn_grey:hover {
                border: 1px solid #1D3A65;
                color: #1D3A65;
                box-shadow: none;
                padding: 9px 29px; } }


        &-right {
            max-width: 358px;
            div {
                max-width: 300px;
                width: 100%;
                position: relative; }

            input {
                height: 100%;
                width: 100%;
                border: 1px solid #EF6752;
                border-radius: 26px;
                padding: 5px 14px;
                box-sizing: border-box; }

            .img-search {
                position: absolute;
                right: 2px;
                top: 50%;
                transform: translate(-50%, -50%);
                &_close {
                    transition: transform ease .4s; }
                &_close:hover {
                    transform: translate(-50%, -50%) rotate(90deg); } }
            img {
                cursor: pointer;
                &:hover {
                    opacity: 0.8; } } } }

    &__accordion {
        display: flex;
        flex-flow: column;
        > *:not(:last-child) {
            margin-bottom: 10px; }

        &__item {
            display: flex;
            flex-flow: column;
            border: 2px solid rgba(101, 134, 184, 0.2);
            border-radius: 24px; }


        &-header {
            display: flex;
            width: 100%;
            cursor: pointer;
            padding: 18px 20px 9px;
            justify-content: space-between;
            position: relative;

            span {
                position: absolute;
                top: 2px; }

            h2 {
                font-size: 24px;
                margin-bottom: 0px;
                line-height: 30px;
                font-weight: bold; }


            h2:first-of-type, h2:last-of-type {
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 300px;
                width: 100%; } }

        &-content {
            padding: 18px 20px;
            padding-top: 0px;
            &__top {
                background: #F6F8FB;
                border-radius: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 18px;
                .left {
                    margin-right: 20px;
                    &__item:not(:last-child) {
                        margin-bottom: 15px; }
                    &__item {
                        display: flex;
                        span:first-child {
                            max-width: 170px;
                            width: 100%;
                            font-weight: bold;
                            font-size: 15px;
                            line-height: 18px; } }
                    .button {
                        max-width: 165px;
                        width: 100%;
                        position: relative;
                        &:hover {
                            opacity: 0.8; }
                        img {
                            position: absolute;
                            right: 15px;
                            top: 12px;
                            transition: transform ease .3s; }
                        img.active {
                            transform: rotate(180deg); } } }
                .right {
                    max-width: 300px;
                    width: 100%;
                    height: 230px;
                    background-color: white;
                    border-radius: 20px;
                    filter: drop-shadow(0px 0px 15px rgba(77, 101, 186, 0.1));
                    padding: 20px;

                    &_Prev {
                        color: #8DD76E; }

                    &_Current {
                        color: #1D3A65; }

                    &_Next {
                        color: #C4C4C4; }

                    li:not(:last-child) {
                        margin-bottom: 20px; }
                    li {
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 30px; }
                        p {
                            margin-bottom: 0px;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 110%; } } } }

            &__bottom {
                margin-top: 23px;

                table {
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    margin-bottom: 15px; }

                tr {
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 0; }


                tbody {
                    tr {
                        border-top: 1px solid #EF6752;
                        border-bottom: 1px solid #EF6752; }

                    tr:not(:first-child) {
                        margin-top: -1px; }
                    tr::not(:last-child) {
                        margin-top: -1px; } }

                th {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    width: 100%; }

                td {
                    width: 100%;
                    display: flex;
                    img {
                        width: 75px;
                        height: 39px;
                        object-fit: contain;
                        margin: auto 0; }

                    .del-product {
                        width: 22px;
                        height: 22px;
                        transition: transform ease .4s;
                        cursor: pointer;
                        &:hover {
                            transform: rotate(180deg); } }
                    button {
                        width: 153px; }
                    p {
                        margin-bottom: 0px; } }


                th:first-child {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 21px; }

                th:first-child, td:first-child {
                    max-width: 537px; }

                th:nth-child(2n), td:nth-child(2n) {
                    text-align: center;
                    max-width: 93px; }

                th:nth-child(3n), td:nth-child(3n) {
                    max-width: 136px;
                    text-align: center; }

                th:last-child, td:last-child {
                    max-width: 228px; }

                td:nth-child(2n), td:nth-child(3n) {
                    p, input {
                        margin: auto;
                        border: 1px solid #EF6752;
                        box-sizing: border-box;
                        border-radius: 18px;
                        padding: 3px 20px;
                        color: #878A95;
                        width: 100%;
                        max-width: 60px;
                        text-align: center; } }

                td:last-child {
                    button {
                        margin: auto; } }

                .desc_bold_itog {
                    text-align: right;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 22px;
                    letter-spacing: -0.408px;
                    margin-right: 48px;
                    margin-bottom: 30px; }

                .buttons {
                    display: flex;
                    justify-content: space-between; }

                .btn_grey {
                    box-sizing: border-box;
                    background-color: #878A95;
                    color: white;
                    padding: 8px 52px;
                    &:hover {
                        padding: 7px 51px;
                        border: 1px solid #878A95;
                        background: white;
                        color: #878A95; } }

                .btn_red:hover {
                        box-shadow: none;
                        padding: 7px 34px;
                        background: white;
                        color: #EF6752;
                        border: 1px solid #EF6752; } } } } }

.content-hidden {
    overflow: hidden; }

@media screen and ( max-width: 450px ) {
    .page_operator-content {
        padding: 0px;
        padding-top: 17px;
        ul {
            margin-bottom: 0px; } }
    .operator-orders {
        &__header {
            height: auto;
            margin-bottom: 0px; }
        &__header-left {
            justify-content: flex-start;
            margin-bottom: 17px;
            .button {
                height: 27px;
                padding: 5px 20px;
                font-size: 14px;
                line-height: 110%;
                &:first-child {
                    margin-right: 8px; } } }
        &__header-right {
            height: 38px;
            input {
                padding: 6px 15px; } } } }





