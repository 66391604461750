.container {
    position: relative;
    padding: 50px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1080px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center; }

.modal-dialog-centered {
    margin: 0px !important; }


body {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.1em;
    color: #000;
    font-family: 'TT Norms', sans-serif !important;
    font-style: normal;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    width: 100%; }

#root {
    min-height: 100vh;
    & > .container {
        min-height: 100vh; } }

h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 105.9%;
    color: #EF6752; }

h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    color: #1D3A65; }

.button {
    border-radius: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    cursor: pointer; }

.btn_red {
    padding: 8px 35px;
    background: #EF6752; }

.btn_support {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 13px;
    padding: 11px 13px 10px 10px;
    background: #1D3A65;
    position: absolute;
    right: 10px;
    bottom: 59px;
    position: fixed;
    img {
        margin-right: 7px; }
    p {
        margin-bottom: 0; }

    &:hover {
        opacity: 0.8; } }

.btn_grey {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background: white;
    border-radius: 38px;
    color: #878A95;
    font-weight: normal; }

.btn_blue {
    background: #1D3A65;
    padding: 6px 24px;
    color: white;
    font-weight: 500; }

.btn_modal {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    text-transform: uppercase; }

.btn_pererashod {
    background: #E63C24;
    width: 150px;
    height: 28px;
    padding: 2px 10px; }

.btn_success {
    background: #85BF57; }

.btn_nedorashod {
    background: #FFA928; }

.btn_courier-active {
    width: 180px;
    height: 30px;
    line-height: 30px;
    padding: 0px !important;
    margin: 0 auto !important;
    margin-top: 20px !important; }

.btn_logout {
    position: absolute;
    right: 0px;
    top: 20px;
    background: #EF6752;
    color: white;
    height: 30px;
    width: 120px;
    &:hover {
        background: white;
        border: 1px solid #EF6752;
        color: #EF6752; } }


.desc_light {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #878A95; }

.menu_contactsType {
    padding: 0 10px;
    transform: translateY(-2px);
    width: 270px;
    margin: 0 auto;
    background-color: #fff;
    height: 0;
    overflow: hidden;
    box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px; }
.menu_contactsType.active {
    position: absolute;
    height: 200px;
    z-index: 200;
    li {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.408px;
        height: 40px;
        margin-left: 13px;
        margin-right: 19px;
        cursor: pointer;
        line-height: 40px;
        p {
            margin-left: 8px; } }
    li:hover p {
        color: #EF6752; }
    li:not(:last-child) {
        border-bottom: 1px solid #EF6752; } }

.custom_select {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    > * {
        width: 100%; }

    &__active {
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        border: 2px solid #EF6752;
        border-radius: 25px;
        padding: 12px 17px;
        font-weight: normal;
        margin-bottom: 0px;
        &_auth {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 8px;
            margin-bottom: 0px;
            img {
                transform: rotate(180deg);
                transition: transform ease .3s; } }
        &_auth.active {
            img {
                transform: rotate(0deg); } } }

    ul {
        box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
        background: #FFFFFF;
        border-radius: 0px 0px 25px 25px;
        padding: 0px 19px 0px 13px;
        width: 90%;
        margin: 0 auto;

        margin-top: -3px;
        li {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.408px;
            padding: 10px 0px 10px 10px;
            &:not(:last-child) {
                border-bottom: 1px solid #EF6752; } } } }

.custom_select_auth {
    ul {
        z-index: 1000;
        position: absolute;
        bottom: 5px;
        transform: translateY(100%); }

    p, li {
        cursor: pointer; }

    li:hover {
        color: #EF6752; } }


@media screen and ( max-width: 450px ) {
    .page h1 {
        font-size: 22px;
        font-weight: bold; }
    .container {
        padding: 15px; } }

