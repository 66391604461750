.resultBlock {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background-color: white;
    max-width: 270px;
    ul {
        max-width: 100%;
        width: 1000%;
        height: 90px;
        overflow-y: scroll;
        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 45px;
            cursor: pointer;
            &:hover p {
                color: #EF6752; } }
        img {
            max-width: 50px;
            height: 25px;
            width: 100%;
            object-fit: contain;
            margin-right: 5px; }
        p {
            font-weight: 500;
            font-size: 10.4923px;
            line-height: 14px;
            color: #000;
            margin-bottom: 0px; } } }

.input {
    display: flex;
    align-items: center;
    height: 38px;
    img {
        max-width: 45px;
        height: 23px;
        width: 100%;
        object-fit: contain;
        margin-right: 5px; }

    p {
        font-weight: 500;
        margin-bottom: 0px;
        line-height: 13px;
        display: flex;
        flex-direction: column;
        max-width: 85%;

        span {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

        span:first-child {
            color: #000;
            font-size: 9.74092px; }

        span:last-child {
            font-size: 8.5233px;
            color: #878A95; } } }


