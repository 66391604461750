.operator-requestes {
    p {
        margin-bottom: 0px; }
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        &-search {
            max-width: 300px;
            width: 100%;
            position: relative;
            margin-right: 22px;
            input {
                width: 100%;
                border: 1px solid #EF6752;
                border-radius: 36px;
                padding: 6px 35px 6px 14px; }

            img {
                position: absolute;
                top: 50%;
                right: 2px;
                transform: translate(-50%, -50%);
                cursor: pointer; } }

        &-filter {
            display: flex;
            align-items: center;
            .button {
                padding: 6px 30px; }
            .button:not(:last-child) {
                margin-right: 20px; }
            .btn_grey:hover {
                border: 1px solid #1D3A65;
                color: #1D3A65;
                padding: 5px 29px; } }


        ul {
            margin-bottom: 0px; } }

    .request {
        border: 2px solid rgba(101, 134, 184, 0.2);
        border-radius: 20px;

        &:not(:last-child) {
            margin-bottom: 10px; }

        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            font-size: 24px;
            line-height: 110%;
            color: #1D3A65;
            padding: 17px 21px 19px 20px;
            cursor: pointer;
            p:first-child {
                min-width: 650px;
                white-space: nowrap; } }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 21px;
            padding: 0px 21px 19px 21px;
            ul {
                width: 100%;
                li {
                    display: flex;
                    align-items: baseline;
                    span:first-child {
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 18px;
                        color: #000000;
                        max-width: 130px;
                        width: 100%; }
                    &:not(:last-child) {
                        margin-bottom: 20px; } } }

            > p {
                width: 100%;
                padding: 10px 18px;
                background: #F6F8FB;
                border-radius: 15px;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #000000;
                margin-bottom: 15px; }

            .button {
                margin-left: auto;
                padding: 8px 54px;
                &:hover {
                    border: 1px solid #EF6752;
                    padding: 7px 53px;
                    color: #EF6752;
                    background: white; } } } } }

@media screen and ( max-width: 450px ) {
    .operator-requestes__header {
        flex-direction: column-reverse;
        align-items: flex-start;
        &-search {}
        &-filter {
            margin-bottom: 17px !important;
            flex-wrap: wrap;
            .button {
                padding: 6px 0px;
                font-size: 14px;
                line-height: 110%;
                &:nth-of-type(1) {
                    width: 55px; }
                &:nth-of-type(2) {
                    width: 108px; }
                &:nth-of-type(3) {
                    width: 140px; } } } } }
