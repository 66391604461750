@import '../Colors/sass/Colors';

.menu {
	z-index: 1;
	position: fixed;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 260px;
	height: 100vh;
	background: #FFFFFF;
	box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
	border-radius: 0px 35px 35px 0px;
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	padding-top: 1vh;
	overflow-y: scroll;

	& .logo {
		margin: 0 auto; }

	& .links {
		margin-top: 30px;

		& .links__item {
			display: flex;
			align-items: center;
			padding: 15px 20px;
			box-sizing: border-box;
			height: 45px;
			padding-left: 52px;

			& p {
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				color: #3D3D3D;
				margin-bottom: 0px; }

			&.active {
				background: rgba(239, 103, 82, 0.3);
				padding-left: 51px;
				p {
					font-weight: 600; } }

			&:hover {
				background: rgba(239, 103, 82, 0.3);
				text-decoration-line: none; } }


		& hr {
			background:#EF6752 {}
			height: 1px;
			margin-left: 52px;
			margin-right: 33px; } } }


