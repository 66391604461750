.customRow {
    display: flex;
    // justify-content: space-between
    margin-bottom: 15px;

    >:first-child {
        flex-basis: 45.5%; }

    >:nth-child(2) {
        flex-basis: 36.5%; }

    .input[type='file'] {
        display: none; } }

.inputFile {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 5px; }

    p {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 18px;
        text-decoration-line: underline;
        color: #1D3A65;
        &:hover {
            text-decoration-line: none; } } }

.imgStep {
    max-width: 70px;
    height: 45px;
    width: 100%;
    object-fit: cover; }

.img {
    height: 38px;
    width: 38px;
    cursor: pointer;
    margin-left: 8px;
    &:hover {
        opacity: 0.8; }

    &.plusImg {
        transform: scale(1.4); }

    &:not(.plusImg) {
        margin-bottom: 1px; } }

.customRowIngredients {
    > input {
        max-width: 30% !important; } }

.customRowProducts {
    width: 58%;
    > :first-child {
        max-width: 270px;
        width: 100%;
        flex-basis: auto; }

    > :last-child {
        flex-basis: auto; } }

.customRowProduct {
    align-items: flex-end;
    .customLabel > p {
        margin-bottom: 10px; } }






