.customSelect {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #A6ACBE; }

.activeItem {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
    border: none !important;
    cursor: pointer;
    justify-content: space-between;
    color: #000 !important;

    & > span {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 5px; }

    > .active {
        transform: rotate(180deg); }

    > img {
        width: 10px;
        // transform: rotate(180deg)

        &.active {
            transform: rotate(180deg); } } }



.list {
    border-top: 1px solid #A6ACBE;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    padding: 12px 5px 15px;
    border-radius: 0px 0px 10px 10px;

    > label {
        display: flex;
        margin-bottom: 0px;

        &:hover {
            color: #EF6752; }

        input {
            margin-right: 10px;
            width: 18px;
            height: 18px; }

        p {
            font-size: 13px;
            line-height: 18px; } } }
