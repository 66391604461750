$dark_green: #334E47;
$main_green: #95BB9F;
$light_green: #D0E2CD;


$font_gray: #A6ACBE;
$font_black: #3D3D3D;

$dark_red: #D42A2A;
$light_red: #F0A8A7;

$main_yellow: #E8B98E;
