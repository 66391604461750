.page-courier {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
        font-weight: bold;
        font-size: 22px;
        line-height: 110%;
        margin-bottom: 35px;
        text-align: center; }

    h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 110%;
        margin-bottom: 0px; }

    h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 0px; }

    p {
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 18px;
        color: #000; }

    .order {
        margin-bottom: 40px;
        &__titles {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 15px; }

        &__block {
            margin-bottom: 15px; }

        .btn_blue {
            width: 220px;
            margin-bottom: 35px;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            padding: 5px 20px;
            box-sizing: border-box !important; }

        a.btn_blue:hover {
            text-decoration-line: none;
            color: white; }
        a.btn_blue {
            margin-bottom: 35px; } }

    .cart {
        display: flex;
        flex-direction: column;
        &-item {
            display: flex;

            align-items: flex-start;
            padding: 8px 0px;
            border-bottom: 1px solid #EF6752;

            &__img {
                margin-right: 7px;
                background: white;
                width: 70px;
                height: 50px;
                object-fit: cover; }

            &__info {
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                width: 100%; }

            &__title {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500; }

            &__articul {
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                color: #878A95; }

            &__count {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                margin-left: auto; } }

        .btn_red:nth-of-type(1) {
            margin-bottom: 20px; }

        .btn_red:nth-of-type(2) {
            margin-top: 0px;
            margin-bottom: 0px; } }

    .btn_red {
        padding: 8px 27px;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin-left: auto;
        margin-top: 23px;
        margin-bottom: 80px; }

    .btn_courier {
        padding: 11px 13px;
        background-color: #1D3A65;
        display: flex;
        align-items: center;
        margin-left: auto;
        img {
            margin-right: 5px; } }

    .btn_red:hover {
        cursor: pointer;
        background: white;
        color: #EF6752;
        border: 1px solid #EF6752;
        padding: 7px 26px; } }
