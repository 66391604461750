.header {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #3D3D3D;
    max-width: 136px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 0px;
    box-shadow: 0 -15px 15px rgba(0, 0, 0, 0.06);
    z-index: 1;
    height: 45px;
    padding-top: 13px;
    text-align: center;
    position: relative;
    margin-bottom: -1px;
    vertical-align: middle; }

.title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px; }

.content {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px 10px 10px 10px;
    padding: 28px 26px 22px 31px;
    height: 80vh;
    position: relative;
    overflow-y: scroll; }

.form {

    label > p {
        max-width: 100%;
        font-size: 16px;
        line-height: 24px; }

    label:nth-of-type(1) > p, label:nth-of-type(2) > p {
        max-width: 270px; }

    label:nth-of-type(3) > p {
        max-width: 600px; }

    .input {
        max-width: 90px;
        text-align: center;

        + span {
            color: #A6ACBE;
            width: fit-content; } } }

.button {
    position: absolute;
    bottom: 22px;
    right: 26px;
    width: 114px;
    height: 38px;
    font-size: 16px;
    line-height: 16px;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer; }

.buttonActive {
    background-color: #EF6752;
    &:hover {
        border: 1px solid #EF6752;
        background-color: white;
        color: #EF6752; } }
