.statisticsWrapper {
    background: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 15px 15px 0 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

    ul {
        padding: 15px 0px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;

        li {
            flex-basis: 33.3%;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: #EF6752; } } } }

.statisticsContent {
    max-height: 100vh;
    overflow: scroll;
    > div {
        padding: 10px 15px; }

    table {

        width: 100%;

        thead tr {
            position: relative; }

        td, th {
            text-align: center;
            vertical-align: middle;
            width: 25%; }

        th {
            color: #1D3A65;
            font-weight: bold;
            font-size: 20px; }

        th:last-child {
            position: absolute;
            right: 30px;
            top: 18px;
            width: fit-content;
            cursor: pointer;

            img {
                transition: transform ease .2s;
                &:hover {
                    transform: rotate(90deg); } } }


        tr:not(:last-child) {
            border-bottom: 1px solid #EF6752; }

        tr {
            font-weight: 500;
            height: 55px; }

        button:hover {
            color: #EF6752;
            border: 1px solid #EF6752;
            background-color: white !important; } } }


