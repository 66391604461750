.customSearch {
    position: relative;
    max-width: 280px;
    width: 25%;
    height: 38px;
    display: flex;

    input {
        width: 100%;
        border-bottom: 1px solid #2F323A; }

    img {
        position: absolute;
        right: 0;
        top: 10px;
        cursor: pointer;
        transition: transform ease .2s;
        &:hover {
            transform: translateY(-50%);
            transform: scale(1.2); } }

    .loader {
        margin: 0 auto; } }


