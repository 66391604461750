.container-modal {
    padding: 15px;
    position: relative;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    align-items: center;


    h2 {
        font-weight: 900;
        font-size: 28px;
        line-height: 110%;
        color: #EF6752;
        margin-bottom: 40px;
        text-align: center; }

    p {
        font-size: 24px;
        line-height: 24px;
        color: #3D3D3D;
        font-weight: bold;
        margin-bottom: 40px; }

    button:nth-of-type(1) {
        margin-bottom: 20px; }

    .courier-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        transition: transform ease .4s;
        cursor: pointer;
        &:hover {
            transform: rotate(180deg); } }

    .button {
        height: 46px;
        width: 100%;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24); }

    .btn_grey {
        background: #878A95;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        padding: 12px 0px;
        color: white !important; }



    &_vozvrat {
        .btn_red {
            margin-bottom: 20px; }

        .btn_red:hover {
            cursor: pointer;
            background: white;
            color: #EF6752;
            border: 1px solid #EF6752;
            padding: 7px 26px;
            box-shadow: none; }

        .btn_blue:hover {
            background: white;
            color: #1D3A65;
            border: 1px solid #1D3A65;
            box-shadow: none;
            padding: 5px 23px; } }

    #content-hidden {
        width: 100%; } }

.container-modal_confirmation-get {
    .order-mask {
        font-weight: bold;
        font-size: 48px;
        line-height: 24px;
        padding: 15px;
        color: #3D3D3D;
        width: 100%;
        text-align: center;
        margin-bottom: 40px; } }


.hint-warning {
    text-align: center;
    color: red !important;
    font-size: 20px !important; }




