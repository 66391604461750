.modal-backdrop {
    height: 100%;
    width: 100%; }

.modal-dialog {
    width: 100% !important;
    justify-content: center; }

.modal-content {
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    justify-content: center; }

.modal-body {
    display: flex;
    justify-content: center; }

@media (min-width: 576px) {
    .modal-dialog {
        max-width: inherit !important; } }

.modal_newOrderFromShop {
    background: #FFFFFF;
    padding: 70px 50px 60px;
    max-width: 737px;
    border-radius: 35px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    .btn_red {
        padding: 12px 100px;
        &:hover {
            border: 1px solid #EF6752;
            color: #EF6752;
            padding: 11px 99px;
            background: white; } }

    h1 {
        margin-bottom: 40px;
        text-align: center;
        font-weight: 900; }

    p {
        margin-bottom: 74px;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        color: #3D3D3D; } }

.modal-close {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 16px;
    cursor: pointer; }

.modal_scanProduct {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    width: 960px;
    padding: 28px 24px 26px 20px; }

.modal_utochnenie {
    max-width: 650px;
    .btns {
        width: 100%;
        > * {
            width: 100%; }
        > :not(:last-child) {
            margin-bottom: 15px; } } }

.modal_comfirmation {
    max-width: 525px; }

.modal_request {
    background-color: white;
    border-radius: 35px;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    max-width: 545px;
    padding: 70px 75px 40px;
    display: flex;
    flex-flow: column;
    height: 594px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .button {
        box-shadow: none; }

    .btn_red:hover {
        border: 1px solid #EF6752;
        background: white;
        color: #EF6752;
        padding: 7px 34px;
        box-shadow: none; }

    .btn_modal {
        height: 46px; }

    .close-modal {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        transition: transform ease .4s;
        &:hover {
            transform: rotate(180deg); } }
    h1 {
        text-align: center;
        font-weight: 900;
        font-size: 44px;
        line-height: 1.1em; }
    > * {
        width: 100%; }

    textarea {
        height: 191px;
        border: 2px solid #EF6752;
        border-radius: 26px;
        resize: none;
        padding: 11px 18px; }

    .form-field {
        height: 40px;
        margin-bottom: 20px;
        border: 2px solid #EF6752;
        border-radius: 26px; }

    p {
        margin-left: 15px;
        line-height: 36px;
        color: #3D3D3D;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px; }

    .form-field_type {
        position: relative;
        ul {
            left: 50%;
            transform: translateX(-50%);
            top: 35px; } }

    svg {
        transition: transform ease .3s; }
    svg.active {
        transform: rotate(-180deg); } }

.modal_utochnenie {
    display: flex;
    background: white;
    border-radius: 35px;
    flex-flow: column;
    justify-content: space-between;
    height: 552px;
    width: 651px;
    padding: 70px 95px 50px;
    position: relative;
    h1 {
        font-weight: 900;
        font-size: 44px;
        line-height: 110%;
        text-align: center;
        white-space: nowrap; }
    .button {
        height: 45px;
        &:hover {
            border: 1px solid #EF6752;
            color: #EF6752;
            background: white;
            padding: 7px 34px;
            box-shadow: none; } }

    p {
        margin-bottom: 0px;
        text-align: center;
        font-size: 48px;
        text-decoration-line: underline;
        font-weight: bold; }

    .form-close {
        position: absolute;
        height: 25px;
        width: 25px;
        top: 15px;
        right: 15px;
        transition: transform ease .4s;
        &:hover {
            transform: rotate(180deg); } } }

.modal_confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    padding: 70px 75px 42px 75px;
    width: 525px;
    height: 494px;
    justify-content: space-between;
    position: relative;

    h1 {
        text-align: center;
        font-weight: 900;
        font-size: 44px;
        line-height: 110%; }

    .order {
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        color: #3D3D3D;
        &-mask {
            font-size: 48px;
            line-height: 24px;
            text-align: center;
            color: #3D3D3D;
            width: 100%; } }

    .conf-close {
        position: absolute;
        height: 25px;
        width: 25px;
        transition: transform ease .4s;
        top: 15px;
        right: 15px;
        cursor: pointer;
        &:hover {
            transform: rotate(180deg); } }

    .button {
        width: 100%;
        &:hover {
            border: 1px solid #EF6752;
            color: #EF6752;
            background: white;
            padding: 7px 35px;
            box-shadow: none; } } }

.modal_changeOrder {
    position: relative;
    background: white;
    height: 650px;
    width: 475px;
    border-radius: 35px;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: column;
    padding: 21px 30px 49px;

    h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 110%;
        color: #1D3A65;
        margin-bottom: 14px; }

    .modal-block {
        &:nth-child(2), &:nth-child(3) {
            margin-bottom: 20px; }
        &:nth-child(4) {
            margin-bottom: 29px; }
        &:nth-child(5) {
            margin-bottom: 55px; }

        &__fields {
            display: flex;
            justify-content: space-between; }

        &__delivery {
            display: flex;
            background-color: #F6F8FB;
            border-radius: 15px;
            width: fit-content;
            padding: 3px;
            color: #878A95;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            p {
                margin-bottom: 0px;
                padding: 8px 16px;
                border-radius: 11px;
                cursor: pointer; }

            p.active {
                background: #EF6752;
                color: white; } }

        &__status {
            display: flex;
            flex-direction: column;
            input {
                margin-right: 14px;
                width: 16px;
                height: 16px; }
            span {
                color: #3D3D3D;
                font-size: 16px;
                line-height: 24px; } } }

    .buttons {
        display: flex;
        flex-wrap: wrap; }

    .button {
        flex-basis: 100%;
        height: 46px;
        text-transform: uppercase;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 25px; }

    .btn_red {
        margin-bottom: 13px; }

    .btn_grey {
        background: #878A95;
        color: white; }


    .form-field {
        height: 46px;
        border: 2px solid #EF6752;
        border-radius: 26px;
        max-width: 200px;
        width: 100%;
        padding: 11px 5px 11px 18px;
        &::placeholder {
            font-size: 16px;
            line-height: 24px;
            font-weight: normal; } } }

label {
    cursor: pointer; }

@media (min-width: 576px) {
    .modal-dialog {
        max-width: inherit !important; } }

@media screen and (max-width: 450px) {
    .modal_request {
        width: 90%;
        height: 450px;
        margin: 0 auto;
        padding: 20px;
        padding-top: 30px;

        h1 {
            font-size: 30px; }

        .close-modal {
            top: 15px;
            right: 15px;
            height: 20px;
            width: 20px; } } }



