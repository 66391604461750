.form-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    width: 485px;
    height: 480px;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    padding: 40px 0; }

.form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    max-width: 375px;
    height: 280px;
    width: 100%;

    &__title {
        color: #EF6752;
        font-size: 44px;
        font-weight: 800;
        line-height: 1.1em;
        text-align: center;
        max-width: 395px;
        margin-bottom: 30px; }

    &-fields {
        display: flex;
        flex-flow: column;
        width: 100%;
        > *:not(:last-child) {
            margin-bottom: 25px; } }

    &__field {
        width: 100%;
        height: 45px;
        border-radius: 25px;

        &_input {
            border: 1px solid #EF6752;
            padding-left: 18px; }

        &_btn {
            border: none;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
            background: #EF6752;
            margin-top: 20px;
            color: white;
            text-transform: uppercase;
            font-size: 18px;
            cursor: pointer; } }

    &-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        &:hover {
            opacity: 0.8; } } }


@media screen and ( max-width: 450px ) {
    .form-wrapper {
        padding: 20px 5px;
        height: auto;
        .form__title {
            font-size: 32px;
            margin-bottom: 20px; }

        .form {
            width: 80%;
            height: 250px; } } }






