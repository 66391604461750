.operator-menu {
    display: flex;
    margin-bottom: 0px;
    width: fit-content;
    li {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 12px 20px;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
        border-radius: 25px 25px 0px 0px;
        img {
            margin-right: 10px; }
        p {
            margin: 0px; } }

    li.active {
        position: relative;
        z-index: 2;
        background-color: white;
        box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.07); } }

.page_operator-content {
    position: relative;
    z-index: 1;
    max-width: 1080px;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
    width: 100%;
    background-color: white;
    border-radius: 0 25px 25px 25px;
    padding: 20px; }

.page_operator-content.active {
    border-radius: 0px 25px 25px 25px; }


@media screen and ( max-width: 450px ) {
    .operator-menu li, li.active, .page_operator-content {
        box-shadow: none !important; }

    .operator-menu li {
        height: 22px;
        padding: 0px;
        margin-right: 38px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: rgba(29, 58, 101, 0.6);
        transition: all ease .2s;
        img {
            display: none; }
        &.active {
            border-bottom: 1px solid #1D3A65;
            color: #1D3A65;
            height: {} } }

    .operator-orders__accordion-content__top {
        flex-direction: column-reverse;
        .right {
            margin-bottom: 20px; }
        .left {
            margin: 0px; } } }
