.seller-item {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 12px 15px 20px 20px;
    position: relative;
    overflow: hidden;

    &.active {
        border: 2px solid #EF6752; }

    &.finished {
        background-color: #ebebeb;
        border: 2px solid #a1a1a1;
        & .product {
            border-top: 1px solid #5c5c5c;
            border-bottom: 1px solid #5c5c5c;
            .product__status {
                display: none; } } }

    h1 {
        text-align: center; }

    &:not(:last-child) {
        margin-bottom: 20px; }

    > *:not(:last-child) {
        margin-bottom: 20px; }

    &-header {
        display: flex;
        h2 {
            font-size: 24px;
            line-height: 110%; }
        h2:first-child {
            flex-basis: 53.6%; }
        h2:last-child {
            margin-left: auto; } }


    &-footer {
        display: flex;
        align-items: center;
        span {
            flex-basis: 53.6%; }
        button {
            margin-left: auto;
            &.disabled {
                background-color: #c4c4c4;
                &:hover {
                    background-color: #c4c4c4;
                    color: #fff;
                    border: 0;
                    cursor: default;
                    padding: 8px 35px; } } }
        .btn_red:hover {
            background-color: white;
            color: #EF6752;
            border: 1px solid #EF6752;
            padding: 7px 34px; }

        .btn_blue:hover {
            background-color: white;
            color: #1D3A65;
            border: 1px solid #1D3A65;
            padding: 5px 23px; } }

    &__itogo {
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.408px; }

    &__blur {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: #64646465
        top: 0;
        left: 0; } }


.product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-top: 1px solid #EF6752;
    border-bottom: 1px solid #EF6752;

    &-close {
        transition: transform ease .4s;
        cursor: pointer;
        &:hover {
            transform: rotate(180deg); } }

    p {
        margin: 0;
        line-height: 22px; }
    .desc_bold {
        font-weight: 700; }

    &:not(:first-child) {
        margin-top: -1px; }

    &__left {
        display: flex;
        align-items: center;
        flex-basis: 53.6%;
        img {
            width: 75px;
            height: 40px;
            object-fit: contain; } }

    &__right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 46.4%; }

    &__counter {
        margin-left: auto;
        margin-right: 39px;
        border: 1px solid #EF6752;
        border-radius: 18px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #878A95;
        width: 57px; }

    &__status_scan {
        p {
            border: none !important;
            color: white !important;
            width: 150px; }
        .orange {
            background-color: #FFA928; }
        .red {
            background-color: #E63C24; }
        .green {
            background-color: #85BF57; } }

    &__status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 260px;
        p {
            text-align: center;
            border: 1px solid #C4C4C4;
            border-radius: 18px;
            line-height: 22px;
            padding: 3px 12px;
            color: #C4C4C4;
            font-size: 14px;
            font-weight: bold; }

        .button {
            color: #85BF57;
            border-color: #85BF57; } }


    &__desc {
        display: flex;
        flex-flow: column; } }


// Modal Scan

.seller-item {
    .seller-scan-header {
        display: flex;
        flex-basis: 46.4%;
        margin-left: auto;
        width: 415px;
        justify-content: space-between;
        margin-bottom: 0px;
        align-items: center;
        div {
            width: 100%;
            max-width: 186px;
            display: flex;
            text-align: center;
            justify-content: space-evenly; }
        h2 {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #000000; }

        .status {
            flex-basis: 100%;
            max-width: 200px;
            text-align: center;
            transform: translateX(-30px);
            vertical-align: middle; } }
    &_scan {
        border-radius: 25px;
        min-width: 958px;
        width: 100%;
        background-color: white; }
    &-footer_scan {
        justify-content: space-between; }

    .btn_blue {
        margin-left: 0; } }

.seller-item_scan {
    max-width: 958px;

    .close-modal__wrapper {
        position: relative;
        width: 100%;
        margin: 0 !important; }

    .close-modal {
        position: absolute;
        right: 5px;
        top: 0;
        cursor: pointer;
        transition: transform .3s;
        &:hover {
            transform: rotate(90deg); } }

    .product {
        &__right_scan {
            max-width: 400px;
            .product__desc {
                flex-flow: row;
                width: 160px;
                justify-content: space-between;
                p {
                    border: 1px solid #EF6752;
                    border-radius: 18px;
                    width: 57px;
                    height: 28px;
                    text-align: center;
                    vertical-align: middle;
                    color: #878A95;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px; }
                p:last-child {
                    transform: translateX(-7px); } } }
        &__status_scan {
            width: auto; } } }



.article_input {
    position: absolute;
    bottom: 0;
    margin: 0 !important;
    left: 0;
    border: none;
    width: 5px;
    background-color: #808080;

    &:focus {
        color: #808080; } }

.seller-hintOrder {
    position: fixed;
    background-color: white;
    padding: 10px;
    border-radius: 15px;
    color: #EF6752;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    right: 5px;
    top: 5px; }

