.tableContent {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px 10px 10px 10px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    height: 746/900*100vh;
    padding: 5px 25px 20px 5px;
    overflow-y: scroll; }

.table {
    position: relative;
    table {
        width: 100%; }

    td:first-child, th:first-child {
        padding-left: 60px; }

    td, th {
        vertical-align: middle;
        text-align: start; }

    th {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #A6ACBE; }

    td {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #000000; }

    td img:hover {
            // opacity: 0.5
            cursor: pointer; }

    tr {
        height: 60px; }

    tbody {
        overflow-y: scroll;
        tr {

            border-top: 1px solid #ECEEEF; } } }

.tableTitle {
    background: white;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0 -15px 15px rgba(0, 0, 0, 0.06);
    width: fit-content;
    position: relative;
    z-index: 1;
    margin-bottom: -1px;
    max-width: 136px;
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    h2 {
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        color: #3D3D3D;
        margin-bottom: 0px; } }

.tablePlus {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: all ease .2s;
    &:hover {
        transform: scale(1.2); } }

.imgTable img {
    height: 38px;
    width: 60px;
    object-fit: contain; }

.animateHeight {
    position: absolute;
    top: 45px; }

.tableTop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    height: 45px; }

.customSearchRecommend {
    position: absolute;
    li.active > p {
        color: red; } }

.imgScale {
    transition: transform ease .2s;
    &:hover {
        transform: scale(1.25); } }
