.list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    li {
        position: relative;
        margin-right: 18px;
        margin-bottom: 15px; } }


.title {
    margin-bottom: 2px;
    font-size: 18px; }

.label {
    margin-bottom: 0px;
    p {
        display: flex;
        align-items: center;
        img {
            margin-right: 5px; }
        span {
            text-decoration-line: underline;
            cover: #1D3A65;
            font-size: 14px;
            line-height: 18px;
            &:hover {
                text-decoration-line: none; } } } }

.listImg {
    height: 45px;
    width: 67px;
    object-fit: cover; }

.closeImg {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    transform: translate(50%, -50%);
    &:hover {
        opacity: 0.8; } }
