.modalAdmin {
    width: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 25px 25px 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px; }

p {
    margin-bottom: 1rem; }

.formAdmin {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    button {
        max-width: 114px;
        padding: 10px 0;
        border-radius: 10px;
        align-self: flex-end; }

    .btn_red {
        background: #EF6752;
        &:hover {
            background-color: white;
            border: 1px solid #EF6752;
            color: #EF6752;
            padding: 9px 0; } }

    > * {
        width: 100%; } }

.labelRow {
    flex-direction: row !important; }

.label {
    display: flex;
    width: 100%;
    height: 38px;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
    align-items: center;
    > * {
        width: 100%; } }

.label > p {
    max-width: 90px;
    margin-bottom: 0px;
    margin-right: 10px; }

.input {
    max-width: 250px;
    border: 1px solid #A6ACBE;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #A6ACBE;
    padding: 7px 15px;
    transition: all ease .3s; }

.input:focus {
    border: 1px solid #414141;
    color: #000000; }


.customLabel {
    height: auto;
    max-width: 100%;
    align-items: flex-start; }

.address ul {
    max-height: 150px;
    overflow-y: scroll;
    border-radius: 0px 0px 10px 10px;

    &::-webkit-scrollbar {
        width: 10px; }

    &::-webkit-scrollbar-track {
        background: #C4C4C4;
        border-radius: 7px; }

    &::-webkit-scrollbar-thumb {
        height: auto;
        background: #EF6752;
        border-radius: 9.33333px; } }

.modalWide {
    width: 645px; }

.formWide {
    .labelImg {
        margin-bottom: 10px !important; }
    .label {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        margin-bottom: 10px;

        > p {
            max-width: 100%;
            margin-bottom: 10px; }

        > label {
            width: fit-content;
            img {
                margin-right: 5px; }
            p {
                text-decoration-line: underline;
                color: #1D3A65;
                font-size: 14px;
                line-height: 18px;
                &:hover {
                    cursor: pointer;
                    text-decoration-line: none; } } } }
    .input {
        max-width: 100%; }

    .select {
        max-width: 100%; }

    .imgWide {
        max-width: 230px;
        width: 100%;
        height: 150px;
        object-fit: cover; } }

.formBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label {
        flex-basis: 45.5%; } }

.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 17px; }

.shopItems {
    .label {
        align-items: center;
        p {
            margin-bottom: 0px;
            font-size: 14px !important; } } }
