@import url('./Colors/sass/Colors.sass');
@import url('./Colors/sass/CommonStyle.sass');

@import './Colors/sass/Colors.sass';

body {
	font-family: 'Proxima Nova Rg'; }


#root > .container_admin {
	box-sizing: content-box;
	margin: 0;
	max-width: 100%;
	padding: 0;
	min-height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start; }

.container_admin .header {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	// position: fixed
	top: 0;
	height: 5vh;
	background: #EF6752;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	button {
		cursor: pointer;
		background-color: inherit;
		margin-right: 30px; }
	button:hover {
		text-decoration-line: underline; } }

.container_admin {
	.page_operator-content {
		max-width: 100%;
		padding-bottom: 2px; }
	.operator-orders__accordion {
		overflow-y: scroll;
		max-height: 74vh; }
	.operator-orders__accordion-header {
		padding: 15px 15px 3px;
		span {
			font-size: 15px;
			top: 0px; }
		h2 {
			font-size: 19px; } } }

.app-content {
	display: flex;
	width: 100%;

	& .content {
		padding-left: 290px;
		padding-top: 2vh;
		padding-right: 25px;
		position: relative;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column; } }


::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	margin-left: 5px; }

/* Track */
::-webkit-scrollbar-track {
	background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
	background: #1e1f2044;
	border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	opacity: .5; }

.errorText {
	color: $dark_red;
	white-space: nowrap;
	font-weight: normal;
	font-size: 12px;
	font-weight: bold; }
.react-datepicker__time-container--with-today-button {
	right: 0;
	left: -87px; }

textarea {
	resize: none !important; }

.loader {
	height: 10px;
	> svg {
		height: 38px; } }

.close-modal-admin {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	transition: transform ease .3s;

	&:hover {
		transform: rotate(90deg); } }
